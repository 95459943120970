import Swiper, {Autoplay, EffectCoverflow, EffectFade, Navigation, Pagination} from 'swiper';
import GLightbox from "glightbox";

import {scroll, animate} from "motion";

document.addEventListener("DOMContentLoaded", function (event) {

    const heroElements = document.querySelectorAll('[data-component="hero"]');

    heroElements.forEach((heroElement) => {
        const bg = heroElement.querySelector('.section_bg');
        scroll(
            animate(bg, {
                opacity: [0.5, 0.1],
                translateY: [0, '20%'],
            }),
            {
                target: heroElement,
                offset: ["start start", "end center"],
            }
        );
    });

    const textImageElements = document.querySelectorAll('[data-component="text-image"]');

    textImageElements.forEach((textImageElement) => {
        const image = textImageElement.querySelector('.section_image');
        const text = textImageElement.querySelector('.section_text_inner');
        scroll(
            animate(image, {
                opacity: [0, 1],
            }),
            {
                target: text,
                offset: ["center end", "center center"],
            }
        );
        scroll(
            animate(text, {
                opacity: [0, 1],
                translateX: [-50, 0],
            }),
            {
                target: text,
                offset: ["center end", "center center"],
            }
        );
    });

    // // INIT MENUBUTTON
    // const menu_button = document.querySelector('[data-behaviour="toggle-menu"]');
    // menu_button.addEventListener('click', () => {
    //     document.body.classList.toggle('body--show');
    // })
    //
    // // INIT LIGHTBOX
    // const lightbox = GLightbox({
    //     selector: '[data-gallery="gallery"]',
    //     touchNavigation: true,
    //     loop: true,
    // });
    //
    // // INIT SWIPER
    // const sliders = document.querySelectorAll('.swiper');
    // sliders.forEach(function (slider) {
    //     const autoSwiper = slider.classList.contains('swiper--auto');
    //     const swiper = new Swiper(slider, {
    //         // configure Swiper to use modules
    //         modules: [Pagination, Navigation, Autoplay, EffectFade],
    //         effect: 'slide',
    //         fadeEffect: {
    //             crossFade: true
    //         },
    //         direction: 'vertical',
    //         loop: true,
    //
    //         autoplay: autoSwiper ? {
    //             delay: 5000,
    //         } : false,
    //
    //         // Navigation arrows
    //         navigation: {
    //             nextEl: '.swiper-button-next',
    //             prevEl: '.swiper-button-prev',
    //         },
    //
    //         pagination: {
    //             el: '.swiper-pagination',
    //             type: 'bullets',
    //             clickable: true,
    //         },
    //     });
    // });

    // FIXED HEADER
    window.addEventListener('scroll', () => {
        if (document.documentElement.scrollTop > 30 || document.body.scrollTop > 30){
            document.body.classList.add('menu--fixed');
        } else {
            document.body.classList.remove('menu--fixed');
        }
    });
});
